import { ArrowDownTrayIcon, AdjustmentsHorizontalIcon, ArrowPathIcon, Cog6ToothIcon, EllipsisVerticalIcon, IdentificationIcon, PresentationChartLineIcon, TableCellsIcon, RocketLaunchIcon, TrashIcon, MagnifyingGlassIcon, ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { type Icon } from "@primer/octicons-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState, type Dispatch, type KeyboardEvent, type SetStateAction } from "react";
import { useList } from "~/providers/ListProvider";
import { useLogger } from "~/providers/LoggerProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { TrackerProvider } from "../../providers/TrackerProvider";
import EvalSummaryModal from "../EvalSummaryModal";
import AllEvalsSummaryModal from "../AllEvalsSummaryModal";
import Button from "../base/Button";
import DropDown from "../base/DropDown";
import HeaderMenu from "../base/HeaderMenu";
import ChevronCorrectIcon from "./ChevronCorrectIcon";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import { api } from "~/utils/api";
import DeleteModal from "../base/DeleteModal";
import SuccessModal from "../base/SuccessModal";
import CompareModal from "../base/CompareModal";
import Input from "../base/Input";
import ChooseListsModal from "../overview/Boost/ChooseListsModal";
import { useLists } from "~/providers/ListsProvider";
export interface MenuItem {
  name: string;
  href?: string;
  icon: Icon;
}
export default function OverviewHeader({
  newBtnHref,
  newBtnFunc,
  newBtnText,
  handleChangeSortOrder,
  menuItems,
  headerText,
  children,
  headerMenuType,
  headerMenuState,
  secondaryBtn,
  hasTableToggle,
  toggleTable,
  downloadTable,
  noContacts,
  isDownloading,
  showTable,
  editNameBtn,
  downloadToml,
  isDownloadingToml,
  searchState
}: Readonly<{
  newBtnText: string;
  newBtnHref?: string;
  newBtnFunc?: () => void;
  handleChangeSortOrder: (name: string) => void;
  menuItems: MenuItem[];
  headerText: string | React.ReactNode;
  children: React.ReactNode;
  headerMenuType: "dropdown" | "tabs" | "";
  headerMenuState: [string, (value: string) => void];
  secondaryBtn?: React.ReactNode;
  hasTableToggle?: boolean;
  toggleTable?: (val: boolean) => void;
  downloadTable?: () => void;
  noContacts?: boolean;
  isDownloading?: boolean;
  downloadToml?: () => void;
  isDownloadingToml?: boolean;
  showTable?: boolean;
  editNameBtn?: React.ReactNode;
  searchState?: [string, Dispatch<SetStateAction<string>>];
}>) {
  const router = useRouter();
  const [filterOpen, setFilterOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [teamAccountUser] = useTeamAccountUser();
  const userId = teamAccountUser?.id;
  const {
    activeTeamAccount
  } = useTeamAccount();
  const activeTeamAccountId = activeTeamAccount?.id;
  const [showModal, setShowModal] = useState(false);
  const [showModalAll, setShowModalAll] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const admin = teamAccountUser?.role === "admin";
  const reEvaluateAllLists = admin && headerText === "Overview";
  const [inputValue, setInputValue] = useState("");
  const [isComparingToml, setIsComparingToml] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [showBoostModal, setShowBoostModal] = useState(false);
  const [addedNewRepoCount, setAddedNewRepoCount] = useState(0);
  const activeTabState = headerMenuState;
  const {
    lists
  } = useLists();
  const listsList = lists?.map(list => {
    return {
      name: list.name,
      id: list.id
    };
  });
  useEffect(() => {
    setInputValue("");
    searchState && searchState[1]("");
  }, [activeTabState[0]]);
  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (searchState && e.key === "Enter") {
      searchState[1](inputValue);
    }
  };
  const {
    data
  } = api.listEvaluation.getNewActiveReposInfo.useQuery({
    teamAccountId: activeTeamAccountId,
    listIds: listsList?.map(list => list.id)
  });
  const newRepoCount = data?.newRepoCount ?? 0;
  const newRepoCountPerList = data?.newRepoCountPerList ?? [];
  const filteredListsList = listsList?.filter(list => newRepoCountPerList.some(newRepoCountList => newRepoCountList.id === list.id && newRepoCountList.newRepoCount > 0));
  const list = useList();
  const logger = useLogger();
  const settingsItems = list ? [{
    name: "Re-evaluate",
    icon: ArrowPathIcon
  }, {
    name: "Settings",
    icon: Cog6ToothIcon
  }, {
    name: "Delete",
    icon: TrashIcon
  }] : admin ? [{
    name: "Re-evaluate",
    icon: ArrowPathIcon
  }, {
    name: "Boost",
    icon: RocketLaunchIcon
  }] : [{
    name: "Boost",
    icon: RocketLaunchIcon
  }];
  const selectItem = (name: string) => {
    if (name === "Settings") {
      router.push("/settings").catch(err => logger.error(err, "OverviewHeader1.tsx"));
    }
    if (name === "Re-evaluate") {
      if (reEvaluateAllLists) {
        setShowModalAll(true);
      } else if (!list || !userId || !activeTeamAccountId) return;
      setShowModal(true);
    }
    if (name === "Boost" && activeTeamAccountId && userId) {
      setShowBoostModal(true);
    }
    if (name === "Delete") {
      if (list && activeTeamAccountId) {
        setDeleteModal(true);
      }
    }
  };
  const handleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleToggleTable = (val: boolean) => {
    if (toggleTable) {
      toggleTable(val);
    }
  };
  return <div className="relative" data-sentry-component="OverviewHeader" data-sentry-source-file="OverviewHeader.tsx">
      {showModal && list && <TrackerProvider initialTracker={list.trackers[0]}>
          <EvalSummaryModal setShowModal={setShowModal} showModal={showModal} list={list} userId={userId} teamAccountId={activeTeamAccountId} />
        </TrackerProvider>}
      {showBoostModal && <ChooseListsModal setShowModal={setShowBoostModal} showModal={showBoostModal} listsList={filteredListsList} newRepoCountPerList={newRepoCountPerList} setSuccessModal={setSuccessModal} teamAccountId={activeTeamAccountId} setAddedNewRepoCount={setAddedNewRepoCount} />}
      {showModalAll && reEvaluateAllLists && <AllEvalsSummaryModal setShowModal={setShowModal} showModal={showModal} userId={userId} teamAccountId={activeTeamAccountId} />}
      {list && deleteModal && <DeleteModal setDeleteModal={setDeleteModal} />}
      {successModal && <SuccessModal successText={`Boosting selected lists: adding ${addedNewRepoCount} new active repos`} setSuccessModal={setSuccessModal} />}
      <div className="w-full bg-white pt-8 z-40 relative pb-4">
        <div className="sticky left-0 ml-auto flex content-center items-center pb-4 ">
          <PresentationChartLineIcon className="sm-icon mr-2" data-sentry-element="PresentationChartLineIcon" data-sentry-source-file="OverviewHeader.tsx" />
          <span className="flex gap-3 items-center flex-1 text-2xl font-semibold">
            {headerText}
            {!!editNameBtn && editNameBtn}
          </span>
          <div className="flex gap-1">
            {secondaryBtn}
            {newBtnHref ? <Link href={newBtnHref} className="ml-3">
                <Button>{newBtnText}</Button>
              </Link> : newBtnFunc && <Button onClick={() => newBtnFunc()}>{newBtnText}</Button>}
          </div>

          <button className="relative" onClick={handleShowDropdown}>
            <EllipsisVerticalIcon className="sm-icon mx-3" data-sentry-element="EllipsisVerticalIcon" data-sentry-source-file="OverviewHeader.tsx" />
            <DropDown closeDropDown={() => setShowDropdown(false)} items={settingsItems} filterOpen={showDropdown} selectItem={selectItem} className="-left-28 top-10 bg-offwhite" reposToBoost={newRepoCount} data-sentry-element="DropDown" data-sentry-source-file="OverviewHeader.tsx" />
          </button>
        </div>
        <div>
          {headerMenuType === "dropdown" && <>
              <button onClick={() => setFilterOpen(!filterOpen)} className="flex w-32 items-end justify-items-end gap-2 pb-2">
                <AdjustmentsHorizontalIcon className="sm-icon" />{" "}
                <span className="flex-1 text-right">Sort By</span>
                <ChevronCorrectIcon className="sm-icon" open={filterOpen} />
              </button>

              <DropDown closeDropDown={() => setShowDropdown(false)} filterOpen={filterOpen} selectItem={handleChangeSortOrder} items={menuItems} className="bg-offwhite" />
            </>}
          <div className="mt-6 flex content-center items-center justify-between border-b h-10">
            {headerMenuType === "tabs" && <HeaderMenu activeTabState={activeTabState} items={menuItems} />}
            {searchState && <div className="mb-2 mr-6 flex content-center items-center  gap-2 pr-2 rounded-md bg-accent  focus-within:ring focus-within:ring-accent-subtle ">
                <Input className={"top-0 left-0 min-w-60 max-w-[160px] max-h-[32px] rounded-r-none"} value={inputValue} setValue={setInputValue} placeholder="Search..." onEnter={handleEnter} />
                <MagnifyingGlassIcon role="button" onClick={() => searchState[1](inputValue)} className="sm-icon stroke-white" />
              </div>}

            <div className="flex gap-2 items-center h-10">
              {!noContacts && <>
                  {hasTableToggle && downloadTable && <div className="flex text-gray-400 w-8">
                      {!isDownloading ? <button onClick={() => downloadTable()} className={`${showTable ? "text-gray-900" : ""} p-1 flex flex-col items-center`} title="Download CSV file">
                          <ArrowDownTrayIcon className="sm-icon" />
                          <div className="text-[0.6rem]">CSV</div>
                        </button> : <LoadingSpinner show={true} className="sm-icon" />}
                    </div>}
                  {hasTableToggle && downloadToml && admin && <>
                      <div className="flex text-gray-400 w-8">
                        {!isDownloadingToml ? <button onClick={() => downloadToml()} className={`${showTable ? "text-gray-900" : ""} p-1 flex flex-col items-center`} title="Download TOML file">
                            <ArrowDownTrayIcon className="sm-icon text-green-500" />{" "}
                            <div className="text-[0.6rem]">TOML</div>
                          </button> : <LoadingSpinner show={true} className="sm-icon" />}
                      </div>
                      <div className="flex text-gray-400 w-8">
                        {!isComparingToml ? <button onClick={() => setShowCompareModal(true)} className={`${showTable ? "text-gray-900" : ""} p-1 flex flex-col items-center`} title="Compare TOML files">
                            <ArrowsRightLeftIcon className="sm-icon text-green-500" />{" "}
                            <div className="text-[0.6rem]">TOML</div>
                          </button> : <LoadingSpinner show={true} className="sm-icon" />}
                      </div>
                      {showCompareModal && <CompareModal activeTabState={activeTabState} activeTeamAccountId={activeTeamAccountId} activeTeamAccountName={activeTeamAccount?.name ?? ""} listId={list?.id ?? ""} setShowCompareModal={setShowCompareModal} setIsComparingToml={setIsComparingToml} isComparingToml={isComparingToml} />}
                    </>}
                </>}
              {hasTableToggle && <div className="flex gap-2 text-gray-400 h-10 items-start">
                  <button onClick={() => handleToggleTable(true)} className={`${showTable ? "text-gray-900" : ""} p-1`} title="Table view">
                    <TableCellsIcon className="sm-icon" />
                  </button>
                  <button onClick={() => handleToggleTable(false)} className={`${!showTable ? "text-gray-900" : ""} p-1`} title="Cards view">
                    <IdentificationIcon className="sm-icon " />
                  </button>
                </div>}
            </div>
          </div>
        </div>
      </div>
      <div className="">{children}</div>
    </div>;
}