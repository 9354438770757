import { type Dispatch, type SetStateAction } from "react";
import { useState } from "react";
import { api } from "~/utils/api";
import { Dialog } from "@headlessui/react";
import Button from "~/components/base/Button";
export default function ChooseListsModal({
  setShowModal,
  showModal,
  newRepoCountPerList,
  listsList,
  setSuccessModal,
  teamAccountId,
  setAddedNewRepoCount
}: Readonly<{
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  newRepoCountPerList: {
    id: string;
    newRepoCount: number;
  }[];
  listsList: {
    id: string;
    name: string;
  }[];
  setSuccessModal: Dispatch<SetStateAction<boolean>>;
  teamAccountId: string;
  setAddedNewRepoCount: Dispatch<SetStateAction<number>>;
}>) {
  const {
    mutate: boostSelectedLists
  } = api.listEvaluation.boostSelectedLists.useMutation({
    onSuccess: () => {
      return;
    }
  });
  const [selectedLists, setSelectedLists] = useState<Set<string>>(new Set());
  const handleCheckboxChange = (id: string) => {
    setSelectedLists(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };
  const handleSelectedLists = () => {
    const newRepoCount = newRepoCountPerList.filter(list => selectedLists.has(list.id)).reduce((acc, list) => acc + list.newRepoCount, 0);
    boostSelectedLists({
      teamAccountId: teamAccountId,
      listIds: Array.from(selectedLists)
    });
    setSuccessModal(true);
    setShowModal(false);
    setAddedNewRepoCount(newRepoCount);
  };
  return <Dialog open={showModal} onClose={() => setShowModal(false)} className="relative z-50" data-sentry-element="Dialog" data-sentry-component="ChooseListsModal" data-sentry-source-file="ChooseListsModal.tsx">
      <div className="fixed inset-0 bg-black bg-opacity-50 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white px-16 py-4 text-left align-middle shadow-xl transition-all" data-sentry-element="unknown" data-sentry-source-file="ChooseListsModal.tsx">
          <Dialog.Title className=" flex flex-col content-center items-center justify-center gap-2 text-lg leading-6 " data-sentry-element="unknown" data-sentry-source-file="ChooseListsModal.tsx">
            <span className="py-2 text-2xl">Choose lists to boost</span>
          </Dialog.Title>
          <Dialog.Description data-sentry-element="unknown" data-sentry-source-file="ChooseListsModal.tsx">
            <div className="whitespace-normal px-12 py-8">
              <ul className="max-h-[500px] overflow-y-scroll list-none space-y-3 p-8">
                {listsList?.map(list => <li key={list.id} className="flex items-center space-x-2">
                    <input type="checkbox" checked={selectedLists.has(list.id)} onChange={() => handleCheckboxChange(list.id)} />
                    <label>{list.name}</label>
                  </li>)}
              </ul>
              <div className="flex justify-end py-4">
                <Button onClick={handleSelectedLists} data-sentry-element="Button" data-sentry-source-file="ChooseListsModal.tsx">
                  Boost selected lists
                </Button>
              </div>
            </div>
          </Dialog.Description>
        </Dialog.Panel>
      </div>
    </Dialog>;
}